<template>
  <div class="container">
    <div class="title">盈利模式</div>
    <div>
      <step fontStyle="font-red" :arrow="stepData.arrow" :leftUrl="stepData.leftUrl" :centerUrl="stepData.centerUrl" :rightUrl="stepData.rightUrl"
      :leftTitle="stepData.leftTitle" :centerTitle="stepData.centerTitle" :rightTitle="stepData.rightTitle"/>
    </div>
    <div class="invite">
      <img src="~assets/images/join/profitbg.png">
      <div class="invite-body">
        <div class="invite-body-top">
          <div class="invite-example">
            <img src="~assets/images/join/yaoqingwoman.png">
            <div class="name">被邀请人</div>
            <div class="invite-text">注册账号，成功 <span>开通B分站</span></div>
            <div class="invite-text">并且完成 <span>N笔订单</span> 交易</div>
          </div>
          <div class="invite-example-arrow">
            <div class="lizi">举个例子</div>
            <img src="~assets/images/join/redarrow.png">
          </div>
          <div class="invite-example">
            <img src="~assets/images/join/yaoqingman.png">
            <div class="name">邀请人</div>
            <div class="invite-text">A分站将获得 <span>开站佣金</span></div>
            <div class="invite-text">以及N笔订单的 <span>提成佣金</span></div>
          </div>
        </div>
        <div class="invite-body-bottom">
          <div>
            * 订单提成佣金=B分站成本-A分站成本（A分站可设置B分站成本）
          </div>
          <div>
            * B分站再发展分站，开站和提成佣金由B分站获得，A分站无佣金；
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Step from 'components/step/Step'
export default {
  components: {
    Step
  },
  data () {
    return {
      stepData: {
        leftUrl: require('assets/images/join/redstep01.png'),
        leftTitle: '注册会员并开通分站',
        centerUrl: require('assets/images/join/redstep02.png'),
        centerTitle: '邀请好友成功下单、开站',
        rightUrl: require('assets/images/join/redstep03.png'),
        rightTitle: '获得订单提成佣金、开站佣金',
        arrow: require('assets/images/join/redarrow.png')
      }
    }
  }
}
</script>

<style scoped src="styles/views/join/joinprofit.css">
</style>
